@import '../../styles/customMediaQueries.css';
@import '../../styles/marketplaceDefaults.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: var(--zIndexTopbar);
  }
}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  display: flex;

  @media (max-width:767px) {
    background-color: #fff;
  }

  @media (--viewportMedium) {
    position: relative;
    padding-top: var(--topbarHeightDesktop);
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

/* Container for side and content */
.layoutWrapperContainer {
  composes: container;
  /* parent aka root is flexbox, this container takes all available space */
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
  }
}

.layoutWrapperFilterColumn {
  display: none;

  @media (--viewportMedium) {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 24px 0 0 24px;
  }

  @media (--viewportLarge) {
    /* Layout */
    padding: 40px 0 0 36px;

    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  @media (min-width: 1440px) {
    padding: 40px 0 0 calc((100vw - 1368px) / 2);
  }

  @media (--viewportXLarge) {
    padding: 40px 0 0 calc((100vw - 1800px) / 2);
  }
}

.filterColumnContent {
  width: 210px;
  padding: 8px 24px 0 0;

  @media (--viewportMedium) {
    padding: 8px 24px 24px 0;
  }

  @media (--viewportLarge) {
    width: 240px;
    padding: 8px 36px 24px 0;
  }

  @media (min-width: 1440px) {
    padding: 8px 60px 24px 0;
  }
}

.filter:first-child {
  padding-top: 0;
}

.resetAllButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);

  /* Layout */
  display: inline;
  margin: 17px 0 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }

  @media (--viewportMedium) {
    margin: 24px 0 0 0;
    padding: 3px 0 5px 0;
  }
}

.page {
  background-color: #fff;
}

.layoutWrapperMain {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 24px;

  @media (--viewportMedium) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 24px 24px 0 24px;
    background-color: var(--colorWhite);
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: var(--colorGrey100);
  }

  @media (--viewportLarge) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 40px 36px 0 36px;
  }

  @media (min-width: 1440px) {
    padding: 40px calc((100vw - 1368px) / 2) 0 59px;
  }

  @media (--viewportXLarge) {
    padding: 40px calc((100vw - 1800px) / 2) 0 59px;
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 0 0;

  @media (--viewportMedium) {
    padding: 0;
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    /* flex-basis: 50%; */
    max-width: 1080px;
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    /* flex-basis: 62.5%; */
  }
}

.error {
  color: var(--colorFail);
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.mainPanel {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.mainPanelMapVariant {
  composes: mainPanel;

  @media (--viewportMedium) {
    padding: 18px 24px 0 24px;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 23px 36px 0 36px;
  }
}

.searchFiltersMobileMap {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none !important;
  }
}

.searchFiltersMobileList {
  padding: 16px 0;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.listingsForGridVariant {
  composes: listings;
}

.listingsForMapVariant {
  composes: listings;
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: calc(100vh - var(--topbarHeightDesktop));
  }

  @media (--viewportLarge) {
    width: 37.5vw;
  }
}

.vendorContainer {
  /* margin: 5% 10%; */
  padding-top: 72px;

  /* background: #ddd; */
  @media (max-width:800px) {
    padding-top: 0;
  }
}

.modalContent {
  color: red;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
}

.submitButton {
  display: flex;
  gap: 7px;
  padding: 0;
  align-items: center;
  justify-content: center;
  max-width: 200px;

  @media (max-width: 767px) {
    flex-direction: column;
    max-width: 170px;
  }

  & button {
    width: 204px;
    height: 56px;
    font: normal normal bold 20px/32px Roc Grotesk;
    letter-spacing: 0px;
    color: #ffffff;
    border-radius: 0;
    text-align: center;

    @media (max-width: 767px) {
      height: 48px;
      width: 100%;
    }
  }
}

.notInterestedBtn {
  color: #b25f87;
}

.heading {
  padding: 10px 15px;
  box-shadow: 0px 0px 8px #ddd;
  background: var(--marketplaceColor);

  & .headingList {
    margin: 0;

    & .matchingFound {
      color: #fff;
      font-size: 1.25rem;
      font-weight: 600;
      letter-spacing: -0.031rem;
    }

    & .servicesSubHeading {
      color: #fff;
      letter-spacing: 0;
      font-size: 0.875rem;
      line-height: 1.375rem;
    }
  }
}

.showCards {
  /* display: flex; */
  /* gap: 20px; */
  overflow: hidden;

  /* justify-content: center; */
  @media (max-width:1023px) {
    flex-direction: column;
  }
}

.showLeftCard {
  width: 400px;
  position: fixed;
  z-index: 99;
  background-color: #fff;
  border-right: 1px solid #d1dae194;

  @media(max-width: 800px) {
    width: 100%;
    position: relative;
  }
}

.unshowRight {
  min-width: 200px;
  background-color: #fff;
}

.showLeftEachCard {
  cursor: pointer;
  padding: 14px 10px;
  border-left: 4px solid #fff;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 30%);
  border-radius: 8px;
}

.showLeftEachCardSelected {
  margin: 0;
  padding: 14px 10px;
  cursor: pointer;
  background: #f3f3f6;
  border-left: 4px solid #000000;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 30%);
  border-radius: 0 8px 8px 0;
}

.middleCard {
  padding: 2px;
  margin-right: 2%;
  justify-content: space-between;
  background-color: rgb(17 22 55 / 1);
}

.titleName {
  font-size: 18px;
}

.categoryName {
  margin: 5px 0;
  font-size: 14px;
  font-weight: 400;
}

.eventDate {
  font-size: 12px;
  font-weight: 400;
  color: #797f83;
}

.additionalDetail {
  background: #d1dae194;
  padding: 10px;
  border-radius: 3px;
  font-size: 12px;
  line-height: normal;

  & .detailAdd {
    font-weight: 600;
    margin-bottom: 5px;
  }

  & .planName {
    & span {
      margin-right: 5px;
    }
  }
}

.categoryRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.matchingListWrapper {
  display: flex;
  /* Use flexbox */
  flex-direction: column;
  /* Stack items vertically */
  height: 100%;
}

.matchingList {
  padding: 15px 15px 85px 15px;
  overflow-y: auto;
  transition: height .5s ease-out;

  @media(max-width: 800px) {
    height: unset !important;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #d1dae194;
  }

  &::-webkit-scrollbar-thumb {
    background: #9d8390;
  }

  &>div {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.noResponse {
  padding: 50px;
  text-align: center;
  background-color: #fff;
}

.showRightCard {
  padding: 50px;
  margin-left: 420px;
  background-color: #fff;

  @media(max-width: 1024px) {
    padding: 25px;
    margin-left: 400px;
  }

  @media(max-width: 800px) {
    padding: 25px;
    margin-left: 0;
  }
}

.buttonsGroup {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 24px;
}

.questionsDetails {
  & .cultureQuestion {
    margin-left: 22px;
  }

  & .answerText {
    display: inline-block;
    margin: 0 0 25px 23px
  }

  & .listItem {
    margin-left: 21px;
    position: relative;

    &::before {
      content: '•';
      position: absolute;
      left: -21px;

    }
  }
}

.creditWrapper {
  & .creditHeading {
    font-size: 20px;
    color: #000;
    font-weight: 600;
  }
}

.creditPurchased {
  margin-top: 30px;
  font-size: 16px;
}

.creditButton {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & a {
    color: #ffffff;
    background: #b25f87;
    width: fit-content;
    padding: 12px 35px;
    font-size: 16px;
    border-radius: 5px;
  }
}

.faqText {
  position: relative;

  /* &:last-child{
     & svg{
        top: 34px;
        @media (max-width: 767px) {
          top: 28px;
        }
      }
    } */
  & svg {
    position: absolute;
    top: 23px;
    right: 21px;

    @media (max-width: 767px) {
      top: 28px;
      right: 15px;
    }
  }
}

& :global(.is-open) {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 12H19' stroke='%233D70A1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    right: 12px;
  }
}

& :global(.is-closed) {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 5V19' stroke='%23949494' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5 12H19' stroke='%23949494' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    right: 12px;
  }
}

& :global(.Collapsible__trigger) {
  /* composes: h1 from global; */
  display: block;
  margin-bottom: 0px;
  padding: 10px 64px 0px 18px;
  color: var(--black-shade-text-black, #272727);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 233.333% */
  height: auto;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (max-width: 1300px) {
    padding: 15px 48px 0px 18px;
  }

  @media (max-width: 767px) {
    margin-bottom: 12px;
    padding: 15px 44px 0px 12px;
    height: 54px;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
  }
}

& :global(.Collapsible) {
  height: 100%;
}

& :global(.Collapsible__contentInner) {
  margin: 0 15px;
  padding-bottom: 16px;
  color: var(--black-shade-para-text, #949494);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: 1px solid #dfdfdf;

  word-break: break-word;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
}

.credits {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;

  & .creditNum {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }

  & .respondText {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: var(--marketplaceColor);
  }
}

.modalCloseBtnHide {
  &>div {
    &>div {
      &>button {
        display: none !important;
      }
    }
  }
}

.cardSpinner {
  &>div {
    margin: 350px auto;
  }
}